import React from 'react';
import { reducer, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import SubscriptionInfo from './SubscriptionInfo';

const SubscriptionSettings = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: null, isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('subscriptions/current', 'GET')
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', payload: resp.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong' }));
  }, []);

  const onSubscriptionCancel = () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    sendRequest('subscriptions/cancel', 'PATCH')
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', data: null });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch(() => dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong' }));
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {state.data ? (
        <div className="pt3">
          <SubscriptionInfo data={state.data} onSubscriptionCancel={onSubscriptionCancel} />
        </div>
      ) : (
        <>
          <h5 className="mb3">Subscription</h5>
          <div className="card-panel red lighten-5">
            <span className="red-text text-darken-2">
              {state.error || 'You do not have an active subscription'}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionSettings;
