import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, round,
} from '../../utils';

const PulsePlot = ({
  data, isActive, intervalType = 'date', forPrinting = false, alertZones = [],
}) => {
  React.useEffect(() => {
    if (isActive) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isActive]);

  if (!data.length) {
    return (
      <NoData />
    );
  }

  let pulsePlot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'pulse',
    label: 'Pulse',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Filter alert zones by field name
  const pulseAlertZones = alertZones.filter((az) => az.field_name === 'Pulse');
  const spo2AlertZones = alertZones.filter((az) => az.field_name === 'Spo2');

  // Add alert zones
  const pulseAlertZonePlots = getAlertZonesPlots(pulseAlertZones, pulsePlot[0].x, 'BPM', '#ef9a9a');

  pulsePlot = [...pulsePlot, ...pulseAlertZonePlots.flat()];

  const averagePulse = round(calculateAverageForKey(data, 'pulse'));

  const pulsePlotMinValue = Math.min(...pulsePlot[0]?.y);
  const pulsePlotMaxValue = Math.max(...pulsePlot[0]?.y);
  const pulsePlotMediumValue = round((pulsePlotMinValue + pulsePlotMaxValue) / 2);

  let spo2Plot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'spo2',
    label: 'SpO<sub>2</sub>',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Add alert zones
  const spo2AlertZonePlots = getAlertZonesPlots(spo2AlertZones, spo2Plot[0].x, 'spo2', '#ef9a9a');

  spo2Plot = [...spo2Plot, ...spo2AlertZonePlots.flat()];

  const averageSpO2 = round(calculateAverageForKey(data, 'spo2'));

  const spo2MinValue = Math.min(...spo2Plot[0]?.y);
  const spo2MaxValue = Math.max(...spo2Plot[0]?.y);
  const spo2MediumValue = round((spo2MinValue + spo2MaxValue) / 2);

  return (
    <>
      <div className="row mb4">
        <div className="col s12">
          {
          !Number.isNaN(averagePulse)
            ? (
              <Plot
                data={pulsePlot}
                style={{ width: '100%' }}
                layout={{
                  width: forPrinting ? '267mm' : null,
                  legend: { x: 0.01, y: -0.5, orientation: 'h' },
                  margin: {
                    l: 50, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'Pulse (BPM)',
                    nticks: 3,
                    tickvals: [pulsePlotMinValue, pulsePlotMediumValue, pulsePlotMaxValue],
                  },
                  xaxis: {
                    title: intervalType === 'time' ? 'Time' : 'Date',
                    tickformat: intervalType === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
            )
            : <NoData />
        }
        </div>

        <div className="col s12">
          {
          !Number.isNaN(averageSpO2)
            ? (
              <Plot
                data={spo2Plot}
                style={{ width: '100%' }}
                layout={{
                  width: forPrinting ? '267mm' : null,
                  legend: { x: 0.01, y: -0.5, orientation: 'h' },
                  margin: {
                    l: 50, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'SpO<sub>2</sub> (%)',
                    nticks: 3,
                    tickvals: [spo2MinValue, spo2MediumValue, spo2MaxValue],
                  },
                  xaxis: {
                    title: intervalType === 'time' ? 'Time' : 'Date',
                    tickformat: intervalType === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
            )
            : <NoData />
        }
        </div>
      </div>

      <div className="page-break-after"> </div>

      <div className="row mt4">
        <div className="col s6 m6">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Pulse</span>
              <div className="center-align">
                {
                data.length
                  ? (
                    <>
                      <h4>{averagePulse}</h4>
                      bpm
                    </>
                  )
                  : <NoData />
              }
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">
                Average SpO
                <sub>2</sub>
              </span>
              <div className="center-align">
                {
                data.length && !Number.isNaN(averageSpO2)
                  ? (
                    <>
                      <h4>{averageSpO2}</h4>
                      bpm
                    </>
                  )
                  : <NoData />
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PulsePlot.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    pulse: PropTypes.number.isRequired,
    spo2: PropTypes.number.isRequired,
  })).isRequired,
  intervalType: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  forPrinting: PropTypes.bool,
  alertZones: PropTypes.arrayOf(PropTypes.shape({
    entry_type: PropTypes.number.isRequired,
    field_name: PropTypes.string.isRequired,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  })),
};

PulsePlot.defaultProps = {
  intervalType: 'date',
  forPrinting: false,
  alertZones: [],
};

export default PulsePlot;
