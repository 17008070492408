import React from 'react';
import PropTypes from 'prop-types';

export default function SubscriptionInfo({ data, onSubscriptionCancel }) {
  const { subscription, product } = data || {};

  if (!subscription || !product) {
    return <div className="card-panel red lighten-4">No subscription details found.</div>;
  }

  // Convert timestamps to readable dates
  const startDate = new Date(subscription.current_period_start * 1000).toLocaleDateString();
  const endDate = new Date(subscription.current_period_end * 1000).toLocaleDateString();

  // If you only have one image in the array, just grab it; otherwise, handle multiple
  const productImage = product.images && product.images.length > 0 ? product.images[0] : null;

  // If marketing_features is an array of { name: string }, we can map them
  // const features = product.marketing_features || [];

  return (
    <div className="row">
      <div className="col s12 m8 offset-m2 l6 offset-l3">
        <div className="card p2 subscription-card">
          {productImage && (
            <div className="card-image">
              <img src={productImage} alt={product.name} />
            </div>
          )}
          <div className="card-content">
            {/* Title / Name of Plan */}
            <h5>{product.name}</h5>

            {/* Status and key subscription info */}
            <p>
              <strong>Status:</strong>
              {' '}
              {subscription.status}
            </p>
            <p>
              <strong>Quantity:</strong>
              {' '}
              {subscription.quantity}
            </p>
            <p>
              <strong>Billing Period:</strong>
              {' '}
              {startDate}
              {' '}
              &mdash;
              {' '}
              {endDate}
            </p>

            {/* Product description, if any */}
            {product.description && (
              <p className="grey-text text-darken-1" style={{ marginTop: '1rem' }}>
                {product.description}
              </p>
            )}

            {/* Features list */}
            {/* {features.length > 0 && (
              <ul className="collection with-header" style={{ marginTop: '1rem' }}>
                <li className="collection-header"><h6>Features</h6></li>
                {features.map((feature) => (
                  <li className="collection-item" key={feature.name}>
                    {feature.name}
                  </li>
                ))}
              </ul>
            )} */}
          </div>

          <div className="card-action">
            <button
              className="btn waves-effect waves-light red"
              onClick={onSubscriptionCancel}
              type="button"
            >
              Cancel Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

SubscriptionInfo.propTypes = {
  data: PropTypes.shape({
    subscription: PropTypes.shape({
      status: PropTypes.string,
      quantity: PropTypes.number,
      current_period_start: PropTypes.number,
      current_period_end: PropTypes.number,
    }),
    product: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string),
      marketing_features: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    }),
  }).isRequired,
  onSubscriptionCancel: PropTypes.func.isRequired,
};
