import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import M from '@materializecss/materialize';

import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';
import Select from '../Components/Select';

const CreatedAt = ({ row: { original: cell } }) => (
  <div>{moment.utc(cell.updated_at, 'YYYY-MM-DD h:mm:ss').local().format('LLL')}</div>
);

CreatedAt.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
      updated_at: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const DeviceActions = ({ row: { original: cell }, onDelete }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const modalRef = React.useRef(null);

  React.useEffect(() => {
    if (modalRef.current) {
      M.Modal.init(modalRef.current, {});
    }
  }, []);

  const handleDelete = () => {
    setIsLoading(true);
    onDelete(cell.id);
  };

  if (isLoading) {
    return <div>Deleting...</div>;
  }

  return (
    <>
      <button type="button" data-target={`delete-modal-${cell.id}`} className="btn red modal-trigger">
        Delete
      </button>

      <div ref={modalRef} id={`delete-modal-${cell.id}`} className="modal">
        <div className="modal-content">
          <h5>Delete Device</h5>
          <p>Are you sure you want to delete this device?</p>
        </div>
        <div className="modal-footer">
          <button type="button" className="modal-close waves-effect btn-flat" onClick={handleDelete}>Yes</button>
          <button type="button" className="modal-close waves-effect btn-flat">No</button>
        </div>
      </div>
    </>
  );
};

DeviceActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

const ConnectedDevices = ({ patientId }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', reload: false, isLoading: false, isError: false,
    },
  );
  const [supportedDevices, setSupportedDevices] = React.useState([]);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/devices`, 'GET')
      .then((resp) => {
        const devices = resp.data;

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: devices,
          reload: false,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });

    // Fetch list of supported devices
    sendRequest('devices/models', 'GET')
      .then((resp) => {
        if (resp.status === 'success') {
          const devices = resp.data.map((device) => ([device.id, `${device.name} (${device.id})`]));
          setSupportedDevices(devices);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [state.reload]);

  const handleDeleteDevice = (deviceId) => {
    if (!isMounted.current) return;

    sendRequest(`connections/patients/${patientId}/devices/${deviceId}`, 'DELETE')
      .then(() => {
        if (!isMounted.current) return;
        // For DELETE requests with 204, we won't get any response content
        // Just update the state to remove the deleted device
        dispatch({
          type: 'FETCH_SUCCESS',
          message: 'Device deleted successfully',
          payload: state.data.filter((device) => device.id !== deviceId),
          reload: false,
        });
      })
      .catch((error) => {
        if (!isMounted.current) return;
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const onAddNewDevice = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    const device = {
      device_id: formData.get('device_id'),
      device_name: formData.get('device_name'),
      device_model: formData.get('device_model'),
    };

    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/devices`, 'POST', device)
      .then((resp) => {
        if (resp.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', message: 'Device added successfully', reload: true });

          // Clear form fields
          event.target.reset();
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: resp.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  const columns = [
    {
      Header: 'Device Id',
      accessor: 'device_id',
    },
    {
      Header: 'Device Name',
      accessor: 'device_name',
    },
    {
      Header: 'Device Model',
      accessor: 'device_model',
    },
    {
      Header: 'Created On',
      Cell: CreatedAt,
      accessor: 'updated_at',
    },
    {
      Header: 'Actions',
      Cell: (props) => <DeviceActions {...props} onDelete={handleDeleteDevice} />,
      disableSortBy: true,
    },
  ];

  return (
    <>
      <h5 className="mt4 mb4">Connected Devices</h5>

      {state.isError && <p className="form-errors">{state.error}</p>}
      {state.message && <p className="form-messages">{state.message}</p>}

      <form className="form mb4" onSubmit={onAddNewDevice}>
        <div className="input-field col s12 m4">
          <input
            id="device_id"
            name="device_id"
            type="text"
          />
          <label htmlFor="device_id">Device ID</label>
        </div>

        <div className="input-field col s12 m4">
          <input
            id="device_name"
            name="device_name"
            type="text"
          />
          <label htmlFor="device_name">Device Name</label>
        </div>

        <div className="input-field col s12 m4">
          <Select
            name="device_model"
            label="Device Model"
            values={supportedDevices}
          />
        </div>

        <button type="submit" className="btn black">Add Device</button>
      </form>

      {state.data.length > 0
        ? (
          <Table columns={columns} data={state.data} centered defaultPageSize={10} />
        )
        : (
          <p className="form-messages">No devices found</p>
        )}
    </>
  );
};

ConnectedDevices.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default ConnectedDevices;
