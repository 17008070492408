import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  calculateAverageForKey, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';
import { ENTRY_TYPE_TO_CODE_MAPPING } from '../Data/constants';

const ENTRY_TYPES = ['BloodPressure', 'PulseOximeter', 'Weight', 'BodyTemperature', 'BloodGlucose'];

const SummaryTable = ({ patientId, startDate, endDate }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: null, isLoading: false, isError: false },
  );

  const fetchEntries = (abortController) => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      from_date: moment(startDate).utc().format(),
      to_date: moment(endDate).utc().format(),
    };

    const promises = ENTRY_TYPES.map(
      (type) => sendRequest(
        `connections/patients/${patientId}/entries`, 'GET',
        { ...opts, entry_type: ENTRY_TYPE_TO_CODE_MAPPING[type] },
      ),
    );

    Promise.all(promises)
      .then((responses) => {
        // Don't update state if component has unmounted
        if (abortController.signal.aborted) return;

        const failedResponses = responses.filter((r) => r.status !== 'success');
        if (failedResponses.length === responses.length) {
          dispatch({ type: 'FETCH_FAILURE', error: failedResponses[0].message });
        } else {
          const data = {};

          responses.forEach((r) => {
            const rawData = r.data || [];

            if (rawData.length) {
              const entryType = rawData[0].entry_type;
              rawData.sort((a, b) => {
                const d1 = moment(a.created_at);
                const d2 = moment(b.created_at);
                if (d1 > d2) {
                  return 1;
                }
                if (d1 < d2) {
                  return -1;
                }
                return 0;
              });

              const firstEntry = rawData[0];
              const lastEntry = rawData[rawData.length - 1];

              data[entryType] = {
                first: firstEntry,
                last: lastEntry,
                entries: rawData,
              };
            }
          });

          dispatch({ type: 'FETCH_SUCCESS', payload: data });
        }
      })
      .catch(() => {
        // Don't update state if component has unmounted
        if (abortController.signal.aborted) return;
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    fetchEntries(abortController);

    return () => {
      abortController.abort();
    };
  }, [startDate, endDate, patientId]);

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div className="pb4">
      <h5>Overview</h5>

      {
      state.data
        ? (
          <table className="centered">
            <thead>
              <tr>
                <th>Vital</th>
                <th>Initial Date</th>
                <th>Initial</th>
                <th>Latest Date</th>
                <th>Latest</th>
                <th>Average</th>
              </tr>
            </thead>
            <tbody>
              {/* BPM */}
              <BloodPressureRow data={state.data[1]} />

              {/* Pulse */}
              <PulseOximeterRows data={state.data[2]} />

              {/* Weight */}
              <WeightRow data={state.data[3]} />

              {/* Temperature */}
              <TemperatureRow data={state.data[12]} />

              {/* Glucose */}
              <BloodGlucoseRow data={state.data[4]} />
            </tbody>
          </table>
        )
        : <h5 className="text-center pt2 pb2">{state.error}</h5>
      }
    </div>
  );
};

SummaryTable.propTypes = {
  patientId: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

const BloodPressureRow = ({ data }) => {
  if (!data) {
    return (
      <tr>
        <th className="center">Blood Pressure</th>
        <td colSpan="5">
          <span className="text-center">Not enough data</span>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <th className="center">Blood Pressure</th>
      <td>
        {
          data.first.systolic
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
      </td>
      <td>
        {
          data.first.systolic
            ? `${data.first.systolic} / ${data.first.diastolic} mmHg`
            : ''
        }
      </td>
      <td>
        {
          data.last.systolic
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
      </td>
      <td>
        {
          data.last.systolic
            ? `${data.last.systolic} / ${data.last.diastolic} mmHg`
            : '-'
        }
      </td>
      <td>
        {
          round(calculateAverageForKey(data.entries, 'systolic'))
            // eslint-disable-next-line max-len
            ? `${round(calculateAverageForKey(data.entries, 'systolic'))} / ${round(calculateAverageForKey(data.entries, 'diastolic'))} mmHg`
            : '-'
        }
      </td>
    </tr>
  );
};

BloodPressureRow.propTypes = {
  data: PropTypes.object,
};

BloodPressureRow.defaultProps = {
  data: null,
};

const PulseOximeterRows = ({ data }) => {
  if (!data) {
    return (
      <tr>
        <th className="center">Pulse Oximeter</th>
        <td colSpan="5">
          <span className="text-center">Not enough data</span>
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr>
        <th className="center">Pulse Oximeter (Pulse)</th>
        <td>
          {
          data.first.pulse
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
        </td>
        <td>
          {
          data.first.pulse
            ? `${data.first.pulse} bpm`
            : ''
        }
        </td>
        <td>
          {
          data.last.pulse
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
        </td>
        <td>
          {
          data.last.pulse
            ? `${data.last.pulse} bpm`
            : '-'
        }
        </td>
        <td>
          {
          round(calculateAverageForKey(data.entries, 'pulse'))
            ? `${round(calculateAverageForKey(data.entries, 'pulse'))} bpm`
            : '-'
        }
        </td>
      </tr>

      <tr>
        <th className="center">Pulse Oximeter (SpO2)</th>
        <td>
          {
          data.first.spo2
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
        </td>
        <td>
          {
          data.first.spo2
            ? `${data.first.spo2}%`
            : ''
        }
        </td>
        <td>
          {
          data.last.spo2
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
        </td>
        <td>
          {
          data.last.spo2
            ? `${data.last.spo2}%`
            : '-'
        }
        </td>
        <td>
          {
          round(calculateAverageForKey(data.entries, 'spo2'))
            ? `${round(calculateAverageForKey(data.entries, 'spo2'))}%`
            : '-'
        }
        </td>
      </tr>
    </>
  );
};

PulseOximeterRows.propTypes = {
  data: PropTypes.object,
};

PulseOximeterRows.defaultProps = {
  data: null,
};

const WeightRow = ({ data }) => {
  if (!data) {
    return (
      <tr>
        <th className="center">Weight</th>
        <td colSpan="5">
          <span className="text-center">Not enough data</span>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <th className="center">Weight</th>
      <td>
        {
          data.first.weight
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
      </td>
      <td>
        {
          data.first.weight
            ? `${data.first.weight} lbs`
            : ''
        }
      </td>
      <td>
        {
          data.last.weight
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
      </td>
      <td>
        {
          data.last.weight
            ? `${data.last.weight} lbs`
            : '-'
        }
      </td>
      <td>
        {
          round(calculateAverageForKey(data.entries, 'weight'))
            ? `${round(calculateAverageForKey(data.entries, 'weight'))} lbs`
            : '-'
        }
      </td>
    </tr>
  );
};

WeightRow.propTypes = {
  data: PropTypes.object,
};

WeightRow.defaultProps = {
  data: null,
};

const TemperatureRow = ({ data }) => {
  if (!data) {
    return (
      <tr>
        <th className="center">Body Temperature</th>
        <td colSpan="5">
          <span className="text-center">Not enough data</span>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <th className="center">Body Temperature</th>
      <td>
        {
          data.first.temperature
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
      </td>
      <td>
        {
          data.first.temperature
            ? `${data.first.temperature} °F`
            : ''
        }
      </td>
      <td>
        {
          data.last.temperature
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
      </td>
      <td>
        {
          data.last.temperature
            ? `${data.last.temperature} °F`
            : '-'
        }
      </td>
      <td>
        {
          round(calculateAverageForKey(data.entries, 'temperature'))
            ? `${round(calculateAverageForKey(data.entries, 'temperature'))} °F`
            : '-'
        }
      </td>
    </tr>
  );
};

TemperatureRow.propTypes = {
  data: PropTypes.object,
};

TemperatureRow.defaultProps = {
  data: null,
};

const BloodGlucoseRow = ({ data }) => {
  if (!data) {
    return (
      <tr>
        <th className="center">Blood Glucose</th>
        <td colSpan="5">
          <span className="text-center">Not enough data</span>
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <th className="center">Blood Glucose</th>
      <td>
        {
          data.first.glucose
            ? moment(data.first.created_at).format('MM/DD/YYYY')
            : ''
        }
      </td>
      <td>
        {
          data.first.glucose
            ? `${data.first.glucose} mg/dL`
            : ''
        }
      </td>
      <td>
        {
          data.last.glucose
            ? moment(data.last.created_at).format('MM/DD/YYYY')
            : '-'
        }
      </td>
      <td>
        {
          data.last.glucose
            ? `${data.last.glucose} mg/dL`
            : '-'
        }
      </td>
      <td>
        {
          round(calculateAverageForKey(data.entries, 'glucose'))
            ? `${round(calculateAverageForKey(data.entries, 'glucose'))} mg/dL`
            : '-'
        }
      </td>
    </tr>
  );
};

BloodGlucoseRow.propTypes = {
  data: PropTypes.object,
};

BloodGlucoseRow.defaultProps = {
  data: null,
};

export default SummaryTable;
