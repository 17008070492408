import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';
import {
  calculateAverageForKey, round,
} from '../../utils';
import NoData from '../../Components/NoData';
import Select from '../../Components/Select';

const ECGPlot = ({ data, isActive, forPrinting = false }) => {
  const [date, setDate] = React.useState(null);
  const [dates, setDates] = React.useState([]);
  const [plotData, setPlotData] = React.useState([]);

  React.useEffect(() => {
    if (!data.length) {
      return;
    }

    const uniqueDates = data
      .map((e) => e.created_at)
      .reduce((acc, item) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, [])
      .map((i) => [i, moment(i).format('YYYY-MM-DD HH:mm:ss')]);

    setDates(uniqueDates);
    setDate(uniqueDates[0][0]);
  }, [data]);

  React.useEffect(() => {
    // Update graph
    if (data.length) {
      if (!date) {
        // nothing to render
        setPlotData([]);
        return;
      }

      const rawData = data.filter((i) => i.created_at === date)[0];

      if (!rawData) {
        // no data found
        setPlotData([]);
        return;
      }
      try {
        // ecg_data can be string or array
        const y = Array.isArray(rawData.ecg_data) ? rawData.ecg_data : JSON.parse(rawData.ecg_data);
        const x = Array.from(Array(y.length).keys());

        const plot = [
          {
            x,
            y,
            type: 'scatter',
            mode: 'lines',
            marker: {
              color: '#f44336',
            },
            name: 'ECG',
          },
        ];
        setPlotData(plot);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        // no data found
        setPlotData([]);
      }
    }
  }, [date, data]);

  React.useEffect(() => {
    if (isActive) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isActive]);

  const averagePulse = round(calculateAverageForKey(data, 'pulse'));

  if (!data.length) {
    return (
      <NoData />
    );
  }

  const minValue = Math.min(...plotData[0]?.y || []);
  const maxValue = Math.max(...plotData[0]?.y || []);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <div className="row">
      <div className="col s12">
        <div className="input-field col s12 m12 hide-on-print">
          <Select
            name="date"
            label="ECG Date"
            values={dates}
            defaultValue={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>

      <div className="col s12">
        {
        !plotData.length
          ? <h5 className="text-center pt2 pb2">Please select date</h5>
          : (
            <>
              <Plot
                data={plotData}
                style={{ width: '100%' }}
                layout={{
                  width: forPrinting ? '267mm' : null,
                  legend: { x: 0.01, y: -1, orientation: 'h' },
                  margin: {
                    l: 60, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'ECG',
                    nticks: 3,
                    tickformat: 'd',
                    tickvals: [minValue, mediumValue, maxValue],
                  },
                  xaxis: {
                    title: 'Time',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
              <p className="block text-center">
                <small>
                  <strong>
                    This graph shows the latest measurement of ECG (
                    {date}
                    ). Please, select the single day in filters to view the ECG for the specific day
                  </strong>
                </small>
              </p>
            </>
          )
      }
      </div>

      <div className="col s12 m6 offset-m3">
        <div className="card blue-grey">
          <div className="card-content white-text">
            <span className="card-title text-center">Average Pulse</span>
            <div className="center-align">
              {
                data.length
                  ? (
                    <>
                      <h4>{averagePulse}</h4>
                      BPM
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ECGPlot.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    ecg_data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    pulse: PropTypes.number,
  })).isRequired,
  isActive: PropTypes.bool.isRequired,
  forPrinting: PropTypes.bool,
};

ECGPlot.defaultProps = {
  forPrinting: false,
};

export default ECGPlot;
