import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const Select = ({
  name, label, values, defaultValue, onChange, multiple, required,
}) => {
  const [id] = React.useState(() => _.uniqueId(`${name}-`));
  const selectRef = React.useRef(null);

  // Convert object to array if needed
  const selectValues = Array.isArray(values) ? values : Object.entries(values);

  // Re-initialize Materialize on mount and whenever our data changes
  React.useEffect(() => {
    if (selectRef.current) {
      M.FormSelect.init(selectRef.current);
    }
  }, [values, defaultValue]);

  // Helper to ensure `defaultValue` is always an array if `multiple` is true
  const getDefaultValue = () => {
    if (multiple) {
      return Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    }
    return defaultValue;
  };

  return (
    <>
      <select
        id={id}
        ref={selectRef}
        name={name}
        required={required}
        onChange={onChange}
        multiple={multiple}
        // Use defaultValue (array if multiple)
        defaultValue={getDefaultValue()}
      >
        {selectValues.map(([optionValue, optionLabel]) => (
          <option
            key={optionValue}
            value={optionValue}
          >
            {optionLabel}
          </option>
        ))}
      </select>
      <label htmlFor={id}>{label}</label>
    </>
  );
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  // This allows either an array or an object for 'values'
  values: PropTypes.oneOfType([
    PropTypes.array, // e.g. [['val1', 'Label 1'], ['val2', 'Label 2']]
    PropTypes.object, // e.g. { val1: 'Label 1', val2: 'Label 2' }
  ]).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
};

Select.defaultProps = {
  defaultValue: '',
  onChange: () => { },
  multiple: false,
  required: false,
};

export default Select;
