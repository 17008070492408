import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

import M from '@materializecss/materialize';
import moment from 'moment';
import NoData from '../../Components/NoData';
import Table from '../../Components/Table';
import { round } from '../../utils';
import Select from '../../Components/Select';
import { ENTRY_TYPES_LABELS } from './constants';

const VitalColumn = ({ row }) => {
  // eslint-disable-next-line camelcase
  const { entry_type } = row.original;
  return <span>{ENTRY_TYPES_LABELS[entry_type]}</span>;
};

VitalColumn.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      entry_type: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

const EntryActions = ({ row: { original: cell } }) => {
  const modalEl = React.useRef(null);
  const [plot, setPlot] = React.useState([]);
  const modalInstance = React.useRef(null);

  React.useEffect(() => {
    if (modalEl.current && plot) {
      modalInstance.current = M.Modal.init(modalEl.current, {});
    }

    return () => {
      if (modalInstance.current && modalInstance.current.destroy) {
        modalInstance.current.destroy();
      }
    };
  }, [plot]);

  React.useEffect(() => {
    let isMounted = true;

    // Generate plot data for ECG
    if (cell.original_entry_type === 5) {
      const y = Array.isArray(cell.ecg_data) ? cell.ecg_data : JSON.parse(cell.ecg_data);
      const x = Array.from(Array(y.length).keys());

      const plotData = [
        {
          x,
          y,
          type: 'scatter',
          mode: 'lines',
          marker: {
            color: '#f44336',
          },
          name: 'ECG',
        },
      ];

      if (isMounted) {
        setPlot(plotData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [cell]);

  // This is needed to resize graph in modal window
  const onModalShow = () => window.dispatchEvent(new Event('resize'));

  // For now we have actions only for ECG
  if (cell.original_entry_type === 5) {
    const minValue = Math.min(...plot[0]?.y || []);
    const maxValue = Math.max(...plot[0]?.y || []);
    const mediumValue = round((minValue + maxValue) / 2);

    return (
      <div>
        <a
          className="waves-effect waves-light btn black modal-trigger"
          href={`#modal-${cell.id}`}
          onClick={onModalShow}
        >
          View ECG
        </a>

        <div id={`modal-${cell.id}`} className="modal modal-fixed-footer" ref={modalEl}>
          <div className="modal-content">
            <Plot
              data={plot}
              layout={{
                legend: { x: 0.01, y: -0.5, orientation: 'h' },
                margin: {
                  l: 60, r: 20, b: 50, t: 50, pad: 2,
                },
                autosize: true,
                yaxis: {
                  title: 'ECG',
                  nticks: 3,
                  tickformat: 'd',
                  tickvals: [minValue, mediumValue, maxValue],
                },
                xaxis: {
                  title: 'Time',
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />
          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect btn-flat">Close</a>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

EntryActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      original_entry_type: PropTypes.string.isRequired,
      ecg_data: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const ENTRY2COLUMNS = {
  0: ['id', 'created_at', 'entry_type', 'systolic', 'diastolic', 'spo2', 'pulse', 'weight', 'temperature', 'bgm'],
  1: ['id', 'created_at', 'systolic', 'diastolic', 'pulse'],
  2: ['id', 'created_at', 'spo2', 'pulse'],
  3: ['id', 'created_at', 'weight'],
  4: ['id', 'created_at', 'glucose'],
  5: ['id', 'created_at', 'pulse', 'actions'],
  12: ['id', 'created_at', 'temperature'],
};

const COLUMNS = [
  {
    Header: 'Date',
    accessor: 'created_at',
    label: 'Date',
  },
  {
    Header: 'ECG',
    accessor: 'ecg_data',
    label: 'ECG',
  },
  {
    Header: 'Vital',
    accessor: 'entry_type',
    label: 'Vital',
    Cell: VitalColumn,
  },
  {
    Header: 'Systolic',
    accessor: 'systolic',
    label: 'Systolic',
    className: 'center relative',
  },
  {
    Header: 'Diastolic',
    accessor: 'diastolic',
    label: 'Diastolic',
    className: 'center relative',
  },
  {
    Header: () => (
      <>
        SpO
        <sub>2</sub>
      </>
    ),
    accessor: 'spo2',
    label: 'SpO2',
    className: 'center relative',
  },
  {
    Header: 'Pulse',
    accessor: 'pulse',
    label: 'Pulse',
    className: 'center relative',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    label: 'Weight',
    className: 'center relative',
  },
  {
    Header: 'Temperature',
    accessor: 'temperature',
    label: 'Temperature',
    className: 'center relative',
  },
  {
    Header: 'Glucose',
    accessor: 'glucose',
    label: 'Glucose',
    className: 'center relative',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    label: 'Actions',
    Cell: EntryActions,
    disableSortBy: true,
  },
];

const PatientEntries = ({
  patient, data, isActive, withDownloadButton, withPagination,
}) => {
  const [visibleEntries, setVisibleEntries] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [filter, setFilter] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);

  React.useEffect(() => {
    if (!isActive) {
      return;
    }

    const entries = data
      .map((e) => {
        const prepareValue = (value, isOutOfRange, uom) => {
          if (value) {
            if (isOutOfRange) {
              return <span className="out-of-alert-zone">{`${value} ${uom}`}</span>;
            }
            return <span>{`${value} ${uom}`}</span>;
          }
          return <span />;
        };

        return {
          id: e.id,
          entry_type: e.entry_type,
          // We use additional attribute because entry_type will be replaced with user friendly value
          original_entry_type: e.entry_type,
          created_at: moment.utc(e.created_at).local().format('MMM D, YYYY h:mm A'),
          created_at_raw: moment.utc(e.created_at).local(),
          systolic: prepareValue(e.systolic, e.is_out_of_range, 'mmHg'),
          systolic_raw: e.systolic,
          diastolic: prepareValue(e.diastolic, e.is_out_of_range, 'mmHg'),
          diastolic_raw: e.diastolic,
          spo2: prepareValue(e.spo2, e.is_out_of_range, '%'),
          spo2_raw: e.spo2,
          pulse: prepareValue(e.pulse, e.is_out_of_range, 'bpm'),
          pulse_raw: e.pulse,
          weight: prepareValue(e.weight, e.is_out_of_range, 'lbs'),
          weight_raw: e.weight,
          ecg_data: e.ecg_data,
          temperature: prepareValue(e.temperature, e.is_out_of_range, '°F'),
          temperature_raw: e.temperature,
          glucose: prepareValue(e.bgm, e.is_out_of_range, 'mg/dL'),
          glucose_raw: e.bgm,
        };
      })
      .sort((a, b) => {
        if (a.created_at_raw > b.created_at_raw) {
          return 1;
        }
        if (a.created_at_raw < b.created_at_raw) {
          return -1;
        }
        return 0;
      });

    // Filter options based on entries returned with API
    const entryTypes = [...new Set(entries.map((e) => e.entry_type))];
    const filterOptions = Object.fromEntries(
      [[0, 'All'], ...Object.entries(ENTRY_TYPES_LABELS).filter((et) => entryTypes.includes(Number(et[0])))],
    );

    setVisibleEntries(entries);
    setFilterOptions(filterOptions);
    setFilter(0);
  }, [data, isActive]);

  React.useEffect(() => {
    if (filter) {
      setVisibleEntries(data.filter((e) => e.entry_type === filter));
    } else {
      setVisibleEntries(data);
    }
  }, [data, filter]);

  React.useEffect(() => {
    if (!withPagination) {
      setPageSize(visibleEntries.length || 25);
    } else {
      setPageSize(25);
    }
  }, [visibleEntries, withPagination]);

  if (!visibleEntries.length) {
    return (
      <NoData />
    );
  }

  const columns = COLUMNS.filter((e) => ENTRY2COLUMNS[filter].includes(e.accessor));

  return (
    <>
      <div className="input-field col s12 m6 hide-on-print">
        <Select
          name="entry_type"
          label="Vital"
          values={filterOptions}
          defaultValue=""
          onChange={(e) => setFilter(Number(e.target.value))}
        />
      </div>
      <Table
        columns={columns}
        data={visibleEntries}
        withDownloadButton={withDownloadButton}
        centered
        defaultSortBy="created_at"
        defaultSortDesc
        downloadFilename={`${patient.patient.first_name}_${patient.patient.last_name || ''}_entries.csv`}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

PatientEntries.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string,
    }),
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    entry_type: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    systolic: PropTypes.number,
    diastolic: PropTypes.number,
    spo2: PropTypes.number,
    pulse: PropTypes.number,
    weight: PropTypes.number,
    temperature: PropTypes.number,
    bgm: PropTypes.number,
    is_out_of_range: PropTypes.bool,
    ecg_data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  })).isRequired,
  isActive: PropTypes.bool.isRequired,
  withDownloadButton: PropTypes.bool,
  withPagination: PropTypes.bool,
};

PatientEntries.defaultProps = {
  withDownloadButton: false,
  withPagination: true,
};

export default PatientEntries;
