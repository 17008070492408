import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { reducer, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';

const PatientsStatistics = ({ patients }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    // Actually we do not use almost all of the data from the response
    // We only need information about time spent
    const opts = {
      from_date: moment().subtract(30, 'days').format('YYYY-MM-DDTHH:mm:ssZ'),
      to_date: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
    };

    sendRequest('connections/patients/statistic', 'GET', opts)
      .then((response) => {
        if (response.status === 'success') {
          const statistics = response.data;

          // Add other data to the state
          // Number of patients
          statistics.totalPatients = patients.length;

          // Patients with no time spent / needs attention
          statistics.noTimeSpentPatients = patients.filter((p) => p.time_trackers.length === 0).length;
          statistics.needsAttentionPatients = patients.filter((p) => p.patient.patient_profile?.needs_attention).length;

          // Adherence rate
          statistics.patientsWithReadings = patients.filter((p) => p.patient.number_of_days_with_measurements >= 16).length;
          statistics.adherenceRate = Math.round(
            patients.length
              ? (statistics.patientsWithReadings / patients.length) * 100
              : 0,
          );

          // Patients with time spent in the last 30 days
          statistics.timeSpentPatients = patients.filter(
            (p) => p.time_trackers.filter(
              (t) => moment(t.started_at).isAfter(moment().subtract(30, 'days')),
            ).length > 0,
          ).length;

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: statistics,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.error });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message || 'Something went wrong' });
      });
  }, [patients]);

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return <div className="red-text">{state.error}</div>;
  }

  return (
    <div className="primary-background pt4 pb4">
      <div className="container">
        <div className="row mb0">

          {/* Card #1 */}
          <div className="col s12 m4 l4">
            <div className="card mt1 mb1">
              <div className="card-content p1">
                <h5 className="text-center mt0">Total Patients</h5>
                <p className="h3 text-center font-weight-bold mt2 mb2">{patients.length}</p>

                <div className="text-center">
                  <h6 className="card-subtitle">Patients with no time spent / needs attention</h6>
                  <p className="font-weight-bold">
                    {state.data.noTimeSpentPatients}
                    &nbsp;/&nbsp;
                    {state.data.needsAttentionPatients}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Card #2 */}
          <div className="col s12 m4 l4">
            <div className="card mt1 mb1">
              <div className="card-content p1">
                <h5 className="text-center mt0">Adherence Rate</h5>
                <p className="h3 text-center font-weight-bold mt2 mb2">
                  {state.data.adherenceRate}
                  %
                </p>

                <div className="text-center">
                  <h6 className="card-subtitle">Patients with reading (daily) / total</h6>
                  <p className="font-weight-bold">
                    {state.data.patientsWithReadings}
                    &nbsp;/&nbsp;
                    {state.data.totalPatients}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Card #3 */}
          <div className="col s12 m4 l4">
            <div className="card mt1 mb1">
              <div className="card-content p1">
                <h5 className="text-center mt0">Average Minutes Spent</h5>
                <p className="h3 text-center font-weight-bold mt2 mb2">
                  {state.data.avg_minutes_spent}
                </p>

                <div className="text-center">
                  <h6 className="card-subtitle">Patients with time spent in the last 30 days / total</h6>
                  <p className="font-weight-bold">
                    {state.data.timeSpentPatients}
                    &nbsp;/&nbsp;
                    {state.data.totalPatients}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

PatientsStatistics.propTypes = {
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      time_trackers: PropTypes.array.isRequired,
      patient: PropTypes.shape({
        patient_profile: PropTypes.shape({
          needs_attention: PropTypes.bool.isRequired,
        }),
        number_of_days_with_measurements: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
};

export default PatientsStatistics;
