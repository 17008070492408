import React from 'react';
import { logout, sendRequest, useAuth } from './utils';

export const useFetch = (path, method, body) => {
  const [status, setStatus] = React.useState('idle');
  const [error, setError] = React.useState('');
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!path) return;

    const fetchData = async () => {
      setStatus('fetching');
      const data = await sendRequest(path, method, body);
      try {
        if (data.status === 'success') {
          setData(data);
          setStatus('fetched');
        } else {
          setStatus('failed');
          setError(data.errors);
        }
      } catch {
        setStatus('failed');
        setError('Something went wrong while fetching data');
      }
    };

    fetchData();
  }, [path]);

  return [status, data, error];
};

export const useFetchAlertZones = (patientId) => {
  const [data, setData] = React.useState([]);
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!patientId) {
      setData([]);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await sendRequest(`connections/patients/${patientId}/alert_zones`, 'GET');

        if (!isMounted.current) return;

        if (response.status === 'error') {
          console.error('Failed to fetch alert zones:', response.message);
          return;
        }

        setData(response.data || []);
      } catch (err) {
        if (!isMounted.current) return;
        console.error('Failed to fetch alert zones:', err.message);
      }
    };

    fetchData();
  }, [patientId]);

  return data;
};

export function useUser({ fetchSubscription = false } = {}) {
  const [logged] = useAuth();
  const [user, setUser] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchUser = React.useCallback(async (forceUpdate = false) => {
    let isMounted = true;

    try {
      // Check if we have cached data and not forcing update
      if (!forceUpdate) {
        const cachedUser = localStorage.getItem('user');
        const cachedTimestamp = localStorage.getItem('userTimestamp');
        const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

        // If we have cached data and it's not stale, use it
        if (cachedUser && cachedTimestamp) {
          const timestamp = parseInt(cachedTimestamp, 10);
          if (Date.now() - timestamp < CACHE_DURATION) {
            if (isMounted) {
              setUser(JSON.parse(cachedUser));
              setIsLoading(false);
            }
            return undefined;
          }
        }
      }

      // If no cache, stale, or force update, fetch from server
      const response = await sendRequest('users/me', 'GET');
      if (response.status === 'success') {
        if (isMounted) {
          setUser(response.data);
          setIsLoading(false);
        }
        // Cache the new data
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('userTimestamp', Date.now().toString());

        // Optionally fetch subscription details
        if (fetchSubscription) {
          const subResponse = await sendRequest('subscriptions/current', 'GET');
          if (subResponse.status === 'success' && isMounted) {
            setSubscription(subResponse.data);
          } else if (isMounted) {
            setSubscription(null);
          }
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('Error while fetching user data');
        logout();
        localStorage.clear();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      if (isMounted) {
        setUser(null);
        setSubscription(null);
        setIsLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSubscription]);

  React.useEffect(() => {
    if (logged) {
      fetchUser();
    } else {
      setIsLoading(false);
      setUser(null);
      setSubscription(null);
    }
  }, [logged, fetchUser]);

  return { user, subscription, isLoading, refreshUser: () => fetchUser(true) };
}
