import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import ReactToPrint from 'react-to-print';
import BloodPressurePlot from './Data/BloodPressure';
import PatientEntries from './Data/Entries';
import PatientDetailsPDF from './DetailsPDF';
import RangeButtonsGroup from './DetailsButtons';
import PulsePlot from './Data/Pulse';
import WeightPlot from './Data/Weight';
import ECGPlot from './Data/ECG';
import Select from '../Components/Select';
import TemperaturePlot from './Data/Temperature';
import GlucosePlot from './Data/Glucose';
import { reducer, sendRequest, getAlertZonesByType } from '../utils';
import Loader from '../Components/Loader';
import { ENTRY_TYPE_TO_CODE_MAPPING, ENTRY_TYPES_LABELS } from './Data/constants';
import { useFetchAlertZones } from '../hooks';

// Export options which are always available
const STATIC_EXPORT_OPTIONS = {
  time_spent: 'Time Spent',
  care_plan: 'Care Plan',
};

const PatientDetails = ({ patient, filters, onFiltersChange }) => {
  const componentRef = React.useRef();

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  const [activeTab, setActiveTab] = React.useState('blood-pressure');
  const [availableExportOptions, setAvailableExportOptions] = React.useState(STATIC_EXPORT_OPTIONS);
  const [intervalType, setIntervalType] = React.useState('date');
  const [exportItems, setExportItems] = React.useState(Object.keys(STATIC_EXPORT_OPTIONS));

  // Fetch all alert zones
  const alertZonesData = useFetchAlertZones(patient.patient.id);

  const handleIntervalChanges = ({ interval, period, startDate, endDate }) => {
    onFiltersChange({ interval, period, startDate, endDate });
  };

  const handleExportItemsChange = React.useCallback((e) => {
    const newTabs = Array.from(e.target.selectedOptions).map((o) => o.value);
    setExportItems(newTabs);
  });

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      from_date: moment(filters.startDate).utc().format(),
      to_date: moment(filters.endDate).utc().format(),
    };

    sendRequest(
      `connections/patients/${patient.patient.id}/entries`, 'GET',
      { ...opts },
    ).then((response) => {
      if (response.status === 'error') {
        dispatch({
          type: 'FETCH_FAILURE',
          error: response.message,
          payload: [],
        });
      } else {
        const rawData = response.data;

        // Sort data by date (created_at)
        rawData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

        // Update available export options
        const newOptions = { ...STATIC_EXPORT_OPTIONS };

        if (rawData.length > 0) {
          newOptions.entries = 'Entries';
        }

        rawData.forEach((entry) => {
          const type = entry.entry_type; // ID

          switch (type) {
            case 1:
              newOptions.blood_pressure = ENTRY_TYPES_LABELS[type];
              break;
            case 2:
              newOptions.pulse = ENTRY_TYPES_LABELS[type];
              break;
            case 3:
              newOptions.weight = ENTRY_TYPES_LABELS[type];
              break;
            case 4:
              newOptions.bgm = ENTRY_TYPES_LABELS[type];
              break;
            case 5:
              newOptions.ecg = ENTRY_TYPES_LABELS[type];
              break;
            case 12:
              newOptions.temperature = ENTRY_TYPES_LABELS[type];
              break;
            default:
              // eslint-disable-next-line no-console
              console.log('Unknown entry type:', type);
              break;
          }
        });

        setAvailableExportOptions(newOptions);

        // Update plot interval type
        // Send 'time' as type if time interval is less than 2 days, otherwise send 'date'
        // to display date and time in the x-axis
        setIntervalType(moment(filters.endDate).diff(moment(filters.startDate), 'days') < 2 ? 'time' : 'date');

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: rawData,
        });
      }
    }).catch((error) => {
      dispatch({
        type: 'FETCH_FAILURE',
        error: typeof error === 'object' ? error.toString() : error,
        payload: [],
      });
    });
  }, [patient.patient.id, filters.startDate, filters.endDate]);

  React.useEffect(() => {
    M.Tabs.init(document.querySelector('.details-tabs'), {});
  });

  const documentTitle = (
    `${patient.patient.first_name} ${patient.patient.last_name || ''}`
    + ` (${moment(filters.startDate).format('l').slice(0, 10)} - ${moment(filters.endDate).format('l').slice(0, 10)})`
  );

  return (
    <div>
      <h5 className="header-with-button mt4">
        Details
      </h5>
      <div className="row d-flex">
        <div className="input-field col s9">
          <Select
            name="export-values"
            label="Export"
            values={availableExportOptions}
            defaultValue={Object.keys(availableExportOptions)}
            multiple
            onChange={handleExportItemsChange}
          />
        </div>
        <div className="col s3" style={{ margin: 'auto' }}>
          <ReactToPrint
            trigger={
              () => <button type="button" className="btn btn-small white black-text ml2 right">Export to PDF</button>
            }
            content={() => componentRef.current}
            documentTitle={documentTitle}
          />
        </div>
      </div>

      <RangeButtonsGroup
        defaultInterval={filters.interval}
        defaultPeriod={filters.period}
        initialStartDate={filters.startDate}
        initialEndDate={filters.endDate}
        callback={handleIntervalChanges}
      />

      {state.isLoading
        ? <Loader />
        : (
          <>
            <div className="row">
              <div className="col s12">
                <ul className="tabs tabs-fixed-width details-tabs">
                  <li className="tab col s3">
                    <a className="active" href="#blood-pressure" onClick={() => setActiveTab('blood-pressure')}>
                      Blood Pressure
                    </a>
                  </li>
                  <li className="tab col s3">
                    <a href="#pulse" onClick={() => setActiveTab('pulse')}>
                      Pulse & S
                      <span style={{ textTransform: 'lowercase' }}>p</span>
                      O
                      <sub>2</sub>
                    </a>
                  </li>
                  <li className="tab col s3"><a href="#weight" onClick={() => setActiveTab('weight')}>Weight</a></li>
                  <li className="tab col s3"><a href="#ecg" onClick={() => setActiveTab('ecg')}>ECG</a></li>
                  <li className="tab col s3"><a href="#temperature" onClick={() => setActiveTab('temperature')}>Temperature</a></li>
                  <li className="tab col s3"><a href="#bgm" onClick={() => setActiveTab('bgm')}>Blood Glucose</a></li>
                  <li className="tab col s3"><a href="#entries" onClick={() => setActiveTab('entries')}>Entries</a></li>
                </ul>
              </div>
              <div id="blood-pressure" className="col s12 mt4">
                <BloodPressurePlot
                  patient={patient}
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.BloodPressure)}
                  intervalType={intervalType}
                  isActive={activeTab === 'blood-pressure'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.BloodPressure)}
                />
              </div>
              <div id="pulse" className="col s12 mt4">
                <PulsePlot
                  patient={patient}
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.PulseOximeter)}
                  intervalType={intervalType}
                  isActive={activeTab === 'pulse'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.PulseOximeter)}
                />
              </div>
              <div id="weight" className="col s12 mt4">
                <WeightPlot
                  patient={patient}
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.Weight)}
                  intervalType={intervalType}
                  isActive={activeTab === 'weight'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.Weight)}
                />
              </div>
              <div id="ecg" className="col s12 mt4">
                <ECGPlot
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.ECG)}
                  isActive={activeTab === 'ecg'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.ECG)}
                />
              </div>
              <div id="temperature" className="col s12 mt4">
                <TemperaturePlot
                  patient={patient}
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.Temperature)}
                  intervalType={intervalType}
                  isActive={activeTab === 'temperature'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.Temperature)}
                />
              </div>
              <div id="bgm" className="col s12 mt4">
                <GlucosePlot
                  patient={patient}
                  data={state.data.filter((entry) => entry.entry_type === ENTRY_TYPE_TO_CODE_MAPPING.BloodGlucose)}
                  intervalType={intervalType}
                  isActive={activeTab === 'bgm'}
                  alertZones={getAlertZonesByType(alertZonesData, ENTRY_TYPE_TO_CODE_MAPPING.BloodGlucose)}
                />
              </div>
              <div id="entries" className="col s12 mt4">
                <PatientEntries
                  patient={patient}
                  data={state.data}
                  isActive={activeTab === 'entries'}
                  withDownloadButton
                />
              </div>
            </div>
          </>
        )}

      <div style={{ display: 'none' }}>
        <div ref={componentRef}>
          <PatientDetailsPDF
            patient={patient}
            startDate={filters.startDate}
            endDate={filters.endDate}
            data={state.data}
            items={exportItems}
            intervalType={intervalType}
            alertZonesData={alertZonesData}
          />
        </div>
      </div>

    </div>
  );
};

PatientDetails.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string,
    }).isRequired,
  }).isRequired,
  filters: PropTypes.shape({
    interval: PropTypes.string.isRequired,
    period: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default PatientDetails;
