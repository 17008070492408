import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, round,
} from '../../utils';

const GlucosePlot = ({
  data, isActive, intervalType = 'date', forPrinting = false, alertZones = [],
}) => {
  React.useEffect(() => {
    if (isActive) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isActive]);

  if (!data.length) {
    return (
      <NoData />
    );
  }

  let plot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'glucose',
    label: 'Glucose, mg/dL',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
    round: false,
  });

  // Add alert zones
  const alertZonePlots = getAlertZonesPlots(alertZones, plot[0].x, 'mg/dL', '#ef9a9a');

  plot = [...plot, ...alertZonePlots.flat()];

  const averageGlucose = round(calculateAverageForKey(data, 'glucose'), 2);

  const minValue = Math.min(...plot[0]?.y);
  const maxValue = Math.max(...plot[0]?.y);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <>
      <div className="row">
        <div className="col s12 mb4">
          <Plot
            data={plot}
            style={{ width: '100%' }}
            layout={{
              width: forPrinting ? '267mm' : null,
              legend: { x: 0.01, y: -0.5, orientation: 'h' },
              margin: {
                l: 50, r: 20, b: 50, t: 50, pad: 2,
              },
              autosize: true,
              yaxis: {
                title: 'Glucose, mg/dL',
                nticks: 3,
                tickvals: [minValue, mediumValue, maxValue],
              },
              xaxis: {
                title: intervalType === 'time' ? 'Time' : 'Date',
                tickformat: intervalType === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
              },
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </div>
      </div>

      <div className="row mt4">
        <div className="col s12 m6 offset-m3">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Glucose</span>
              <div className="center-align">
                {
                data.length
                  ? (
                    <>
                      <h4>{averageGlucose}</h4>
                      mg/dL
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GlucosePlot.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    glucose: PropTypes.number.isRequired,
  })).isRequired,
  isActive: PropTypes.bool.isRequired,
  intervalType: PropTypes.oneOf(['date', 'time']),
  forPrinting: PropTypes.bool,
  alertZones: PropTypes.arrayOf(PropTypes.shape({
    entry_type: PropTypes.number.isRequired,
    field_name: PropTypes.string.isRequired,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  })),
};

GlucosePlot.defaultProps = {
  intervalType: 'date',
  forPrinting: false,
  alertZones: [],
};

export default GlucosePlot;
