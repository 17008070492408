import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';

import AddAlertZone from './Patients/Settings/AlertZones/Add';
import AddReminder from './Patients/CarePlan/Reminders/Add';
import AssignedActions from './Actions/List';
import ColleaguesList from './Colleagues/List';
import SearchOrAddColleague from './Colleagues/Add';
import Dashboard from './Admin/Dashboard';
import DoctorsDashboard from './Admin/DoctorsDashboard';
import EditAlertZone from './Patients/Settings/AlertZones/Edit';
import EditReminder from './Patients/CarePlan/Reminders/Edit';
import Login from './Auth/Login';
import Help from './Help';
import Menu from './Menu';
import Patient from './Patients/View';
import PatientsList from './Patients/List';
import Register from './Auth/Register';
import ResetPassword from './Auth/ResetPassword';
import PatientInfoEdit from './Patients/Edit';
import PrivateRoute from './Auth/PrivateRoute';
import Requests from './Requests/List';
import SendRequest from './Requests/Add';
import MySettings from './Auth/MySettings';
import AnalyticsDashboard from './Admin/AnalyticsDashboard';
import Feedback from './Feedback';
import InvitePatient from './Patients/InvitePatient';
import ScrollToTop from './Components/ScrollToTop';
import ChangeLogs from './ChangeLogs/List';
import WhatsNew from './ChangeLogs/WhatsNew';
import AddOrEditChangeLog from './ChangeLogs/AddOrEdit';
import UsageStatisticsDashboard from './Admin/UsageStatisticsDashboard';

function App() {
  return (
    <div>
      <Menu />
      <ScrollToTop />
      <Switch>
        <PrivateRoute exact path="/" component={PatientsList} />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/restore-password">
          <ResetPassword />
        </Route>
        <Route exact path="/help">
          <Help />
        </Route>
        <PrivateRoute exact path="/feedback" component={Feedback} />
        {/* Requests */}
        <PrivateRoute exact path="/requests" component={Requests} />
        <PrivateRoute exact path="/requests/add" component={SendRequest} />
        {/* Assigned Actions */}
        <PrivateRoute exact path="/actions" component={AssignedActions} />
        {/* Colleagues */}
        <PrivateRoute exact path="/colleagues" component={ColleaguesList} />
        <PrivateRoute exact path="/colleagues/add" component={SearchOrAddColleague} />
        {/* Patients */}
        <PrivateRoute exact path="/patients/:patientId" component={Patient} />
        <PrivateRoute exact path="/patients/:patientId/edit" component={PatientInfoEdit} />
        <PrivateRoute exact path="/patients/:patientId/alertzones" component={AddAlertZone} />
        <PrivateRoute exact path="/patients/:patientId/alertzones/:groupId" component={EditAlertZone} />
        <PrivateRoute exact path="/patients/:patientId/reminders" component={AddReminder} />
        <PrivateRoute exact path="/patients/:patientId/reminders/:ids" component={EditReminder} />
        {/* Admin */}
        <PrivateRoute exact path="/admin" component={Dashboard} />
        <PrivateRoute exact path="/admin/doctors" component={DoctorsDashboard} />
        <PrivateRoute exact path="/admin/analytics" component={AnalyticsDashboard} />
        <PrivateRoute exact path="/admin/statistics" component={UsageStatisticsDashboard} />

        {/* Change Logs */}
        <PrivateRoute exact path="/whats-new" component={WhatsNew} />
        <PrivateRoute exact path="/changelogs" component={ChangeLogs} />
        <PrivateRoute exact path="/changelogs/add" component={AddOrEditChangeLog} />
        <PrivateRoute exact path="/changelogs/:changelogId" component={AddOrEditChangeLog} />
        {/* Settings */}
        <PrivateRoute exact path="/settings" component={MySettings} />
        {/* Invite Patient */}
        <PrivateRoute exact path="/invite-patient" component={InvitePatient} />
      </Switch>
    </div>
  );
}

export default App;
