import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, round,
} from '../../utils';

const WeightPlot = ({
  data, isActive, intervalType = 'date', forPrinting = false, alertZones = [],
}) => {
  React.useEffect(() => {
    if (isActive) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isActive]);

  if (!data.length) {
    return (
      <NoData />
    );
  }

  let plot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'weight',
    label: 'Weight',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Add alert zones
  const weightAlertZones = alertZones.filter((az) => az.field_name === 'Weight');
  const alertZonePlots = getAlertZonesPlots(weightAlertZones, plot[0].x, 'lbs', '#ef9a9a');

  plot = [...plot, ...alertZonePlots.flat()];

  const averageWeight = round(calculateAverageForKey(data, 'weight'), 2);

  const minValue = Math.min(...plot[0]?.y);
  const maxValue = Math.max(...plot[0]?.y);
  const mediumValue = round((minValue + maxValue) / 2);

  return (
    <>
      <div>
        <div className="col s12 mb4">
          <Plot
            data={plot}
            style={{ width: '100%' }}
            layout={{
              width: forPrinting ? '267mm' : null,
              legend: { x: 0.01, y: -0.5, orientation: 'h' },
              margin: {
                l: 50, r: 20, b: 50, t: 50, pad: 2,
              },
              autosize: true,
              yaxis: {
                title: 'Weight (lbs)',
                nticks: 3,
                tickvals: [minValue, mediumValue, maxValue],
              },
              xaxis: {
                title: intervalType === 'time' ? 'Time' : 'Date',
                tickformat: intervalType === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
              },
            }}
            config={{
              displayModeBar: false,
            }}
          />
        </div>
      </div>

      <div className="row mt4">
        <div className="col s12 m6 offset-m3">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Weight</span>
              <div className="center-align">
                {
                data.length
                  ? (
                    <>
                      <h4>{averageWeight}</h4>
                      lbs
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-break-after"> </div>

      <div className="row mt4">
        <WeightMetricsTable data={data} />
      </div>
    </>
  );
};

WeightPlot.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    weight: PropTypes.number.isRequired,
  })).isRequired,
  isActive: PropTypes.bool.isRequired,
  intervalType: PropTypes.oneOf(['date', 'time']),
  forPrinting: PropTypes.bool,
  alertZones: PropTypes.arrayOf(PropTypes.shape({
    entry_type: PropTypes.number.isRequired,
    field_name: PropTypes.string.isRequired,
    min_value: PropTypes.number,
    max_value: PropTypes.number,
  })),
};

WeightPlot.defaultProps = {
  intervalType: 'date',
  forPrinting: false,
  alertZones: [],
};

const WeightMetricsTable = ({ data }) => {
  // Leave only records with weight_data filled
  const filteredData = data
    .filter((i) => i.weight_data)
    .map((i) => i.weight_data);

  const aveHeartRate = round(calculateAverageForKey(filteredData, 'heartRate'), 0);
  const aveBoneDensity = round(calculateAverageForKey(filteredData, 'boneMuscle'), 2);
  const aveBodyFat = round(calculateAverageForKey(filteredData, 'bodyFatPercent'), 2);
  const aveVisceralFat = round(calculateAverageForKey(filteredData, 'visceralFat'), 2);
  const aveWaterContent = round(calculateAverageForKey(filteredData, 'waterContent'), 2);
  const aveBMI = round(calculateAverageForKey(filteredData, 'bmi'), 2);
  const aveBMR = round(calculateAverageForKey(filteredData, 'bmr'), 2);
  const aveBoneMass = round(calculateAverageForKey(filteredData, 'boneMass'), 2);
  const aveBodyAge = round(calculateAverageForKey(filteredData, 'bodyAge'), 2);
  const aveProteinPercent = round(calculateAverageForKey(filteredData, 'proteinPercent'), 2);
  const aveMuscleMass = round(calculateAverageForKey(filteredData, 'muscleMass'), 2);
  const aveMuscleRate = round(calculateAverageForKey(filteredData, 'muscleRate'), 2);
  const aveSkeletalMusclePercent = round(calculateAverageForKey(filteredData, 'skeletalMusclePercent'), 2);
  const aveSubcutaneousFatPercent = round(calculateAverageForKey(filteredData, 'subcutaneousFatPercent'), 2);

  return (
    <table className="rounded-table z-depth-1">
      <tbody>
        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveVisceralFat
                ? (
                  <>
                    {aveVisceralFat}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Heart Rate</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBMI
                ? (
                  <>
                    {aveBMI}
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">BMI</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveHeartRate
                ? (
                  <>
                    {aveHeartRate}
                    <small className="font-weight-normal"> bpm</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Heart Rate</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBoneDensity
                ? (
                  <>
                    {aveBoneDensity}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Bone Muscle</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBodyFat
                ? (
                  <>
                    {aveBodyFat}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Fat</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveWaterContent
                ? (
                  <>
                    {aveWaterContent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Water</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBMR
                ? (
                  <>
                    {aveBMR}
                    <small className="font-weight-normal"> kcal</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">BMR</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBoneMass
                ? (
                  <>
                    {aveBoneMass}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Bone Density</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveBodyAge
                ? (
                  <>
                    {aveBodyAge}
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Body Age</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveProteinPercent
                ? (
                  <>
                    {aveProteinPercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Protein</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveMuscleMass
                ? (
                  <>
                    {aveMuscleMass}
                    <small className="font-weight-normal"> lb</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Muscle Mass</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveMuscleRate
                ? (
                  <>
                    {aveMuscleRate}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Muscle Rate</span>
          </td>
        </tr>

        <tr>
          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveSkeletalMusclePercent
                ? (
                  <>
                    {aveSkeletalMusclePercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Skeletal Muscle</span>
          </td>

          <td className="pt2 pb2">
            <span className="d-block m0 text-center h5 font-weight-bold">
              {
              aveSubcutaneousFatPercent
                ? (
                  <>
                    {aveSubcutaneousFatPercent}
                    <small className="font-weight-normal"> %</small>
                  </>
                )
                : <small className="font-weight-normal">-</small>
            }
            </span>
            <span className="d-block text-center grey-text">Subcutaneous Fat</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

WeightMetricsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    weight_data: PropTypes.shape({
      heartRate: PropTypes.number,
      boneMuscle: PropTypes.number,
      bodyFatPercent: PropTypes.number,
      visceral_fat: PropTypes.number,
      waterContent: PropTypes.number,
      bmi: PropTypes.number,
      bmr: PropTypes.number,
    }),
  })).isRequired,
};

export default WeightPlot;
