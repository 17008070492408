import M from '@materializecss/materialize';
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import moment from 'moment';
import { useAuth, logout, sendRequest, reducer } from './utils';
import { useUser } from './hooks';
import SubscribeButton from './Subscriptions/SubscribeButton';

const HeaderRightMenu = ({ user, subscription, className }) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: [], message: '', error: '', isLoading: true, isError: false,
    },
  );
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    if (user) {
      sendRequest('change_logs', 'GET')
        .then((response) => {
          if (response.status === 'error') {
            dispatch({ type: 'FETCH_FAILURE', error: response.message });
            return;
          }

          // Preprocess requests
          let changelogs = response.data.map(
            (l) => ({
              id: Number(l.id),
              version: l.version,
              date_released: moment(new Date(l.date_released)).format('MMMM DD, YYYY'),
              change_notes: l.change_notes,
              module: l.module,
            }),
          );

          // Sort by ID and filter only after user.last_acknowledged_changelog_id (if any)
          changelogs = changelogs.sort((a, b) => a.id > b.id);

          if (user.last_acknowledged_changelog_id) {
            changelogs = changelogs.filter((l) => l.id > Number(user.last_acknowledged_changelog_id));
          }

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: changelogs,
          });
        })
        .catch((error) => {
          dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
        });
    }
  }, [user]);

  React.useEffect(() => {
    // Hide dropdown on click outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropdownRef.current.style.display = 'none';
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleShowHideChangelogs = (e) => {
    e.preventDefault();

    if (dropdownRef.current.style.display === 'block') {
      dropdownRef.current.style.display = 'none';
    } else {
      dropdownRef.current.style.display = 'block';
    }
  };

  const handleMarkAsRead = () => {
    dispatch({ type: 'FETCH_INIT' });

    const lastChangelogId = state.data[state.data.length - 1].id;

    sendRequest('users/me', 'PUT', { last_acknowledged_changelog_id: lastChangelogId })
      .then((response) => {
        if (response.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: [],
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      });
  };

  return (
    <div className="right">
      {/* TODO: Move outside of ChangeLogs block or rename component! */}
      {
        !subscription && (
          <SubscribeButton />
        )
      }

      <div className={`ml2 changelogs-container ${className}`}>
        <a href="#/" onClick={handleShowHideChangelogs}>
          {
          state.data.length > 0 && (
            <i className="material-icons changelogs-alert">priority_high</i>
          )
        }
          <i className="material-icons">update</i>
        </a>
        <div className="changelogs-dropdown black-text pt2 pb2 pl3 pr3" ref={dropdownRef}>
          {
          state.data.length > 0
            ? (
              <>
                {
                state.data.map((l) => (
                  <div key={l.id} className="changelog">
                    <div className="changelog-header">
                      <p style={{ fontSize: '1rem' }}>
                        <span className="badge black white-text left ml0 mr1">{l.module}</span>
                        {l.date_released}
                        {' '}
                        :
                        {' '}
                        {l.version}
                        {' '}
                      </p>
                    </div>
                    <div className="changelog-body">
                      <p style={{ whiteSpace: 'pre-wrap', fontSize: '.75rem' }}>{l.change_notes}</p>
                    </div>

                    <hr />
                  </div>
                ))
              }

                <button type="button" className="btn btn-small black" onClick={handleMarkAsRead}>Mark all as read</button>
              </>
            )
            : (
              <p className="center-align">No new change logs.</p>
            )
        }
        </div>
      </div>
    </div>
  );
};

HeaderRightMenu.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    last_acknowledged_changelog_id: PropTypes.string,
  }),
  subscription: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }),
  className: PropTypes.string,
};

HeaderRightMenu.defaultProps = {
  user: {
    id: 0,
    last_acknowledged_changelog_id: 0,
  },
  subscription: null,
  className: '',
};

const Menu = () => {
  const [logged] = useAuth();
  const { user, subscription, isLoading } = useUser({ fetchSubscription: true });

  React.useEffect(() => {
    M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
  }, [logged]);

  return (
    <nav className="grey darken-4">
      <div className="nav-wrapper container">
        <NavLink to="/" className="brand-logo"><img className="" src="/images/vive-now-logo.png" alt="Logo" /></NavLink>

        <a href="#/" data-target="mobile-menu" className="sidenav-trigger">
          <i className="material-icons">menu</i>
        </a>

        {user && (
          <HeaderRightMenu user={user} subscription={subscription} className="right hide-on-med-and-down" />
        )}

        <ul className="sidenav" id="mobile-menu">
          <li>
            <NavLink to="/" className="mt4 mb4"><img src="/images/vive-now-logo.png" alt="Logo" /></NavLink>
          </li>
          {logged && !isLoading
            ? (
              <>
                <li>
                  <a className="nav-link" href="https://vivenow.health">
                    <i className="material-icons mr2">arrow_back</i>
                    Return to ViveNow site
                  </a>
                </li>

                <li><div className="divider" /></li>

                {user && (user.is_staff) && (
                <li>
                  <NavLink to="/admin" className="nav-link" activeClassName="active">
                    <b>Admin Panel</b>
                  </NavLink>
                  <NavLink to="/changelogs" className="nav-link" activeClassName="active">Admin: What&apos;s New</NavLink>

                  <div className="divider" />
                </li>
                )}

                <li><NavLink to="/" className="nav-link" activeClassName="active">Patients Dashboard</NavLink></li>
                <li>
                  <NavLink to="/actions" className="nav-link" activeClassName="active">
                    Actions
                    {
                      user && user.number_of_actions > 0
                      && (
                      <span className="badge new black white-text ml2">
                        {user.number_of_actions}
                      </span>
                      )
                    }
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/requests" className="nav-link" activeClassName="active">
                    Requests
                    {
                      user && user.number_of_requests > 0
                      && (
                        <span className="badge new black white-text ml2">
                          {user.number_of_requests}
                        </span>
                      )
                    }
                  </NavLink>
                </li>
                <li><NavLink to="/colleagues" className="nav-link" activeClassName="active">Colleagues</NavLink></li>
                <li><NavLink to="/settings" className="nav-link" activeClassName="active">My Settings</NavLink></li>
                {/* <li><NavLink to="/help" className="nav-link" activeClassName="active">Help</NavLink></li> */}
                <li><NavLink to="/feedback" className="nav-link" activeClassName="active">Feedback</NavLink></li>
                <li><NavLink to="/whats-new" className="nav-link" activeClassName="active">What&apos;s New</NavLink></li>
                <li>
                  <NavLink
                    to="/"
                    className="nav-link"
                    activeClassName="active"
                    onClick={() => { logout(); localStorage.clear(); }}
                  >
                    Logout
                  </NavLink>
                </li>

              </>
            )
            : (
              <>
                <li><a className="nav-link" href="https://vivenow.health">Home</a></li>
                {/* <li><NavLink to="/help" className="nav-link" activeClassName="active">Help</NavLink></li> */}
                <li><NavLink to="/login" className="nav-link" activeClassName="active">Login</NavLink></li>
                {/* <li><NavLink to="/register" className="nav-link" activeClassName="active">Register</NavLink></li> */}
              </>
            )}
          <li className="sticky">
            <p className="black-text text-center mr1 ml1 " style={{ lineHeight: '24px' }}>
              Please contact
              {' '}
              <strong>855-220-1213</strong>
              {' '}
              or
              {' '}
              <strong>239-323-0895</strong>
              {' '}
              for assistance with the provider portal,
              patient application, or devices (9 AM EST - 9 PM EST)
            </p>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
