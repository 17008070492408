import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

import { useHistory, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../utils';

import ConnectedDevices from './ConnectedDevices';
import Select from '../Components/Select';
import Loader from '../Components/Loader';

const PATIENT_STATUSES = {
  0: 'Inactive',
  1: 'Active',
  2: 'Ununrolled',
  3: 'Graduated',
};

function calculateImperialBMI(patient) {
  // Weight should be in lbs
  const weight = parseFloat(patient.patient.patient_profile.weight_in_lbs);
  const rawHeight = patient.patient.patient_profile.height_in_ft;

  if (!rawHeight) {
    return '-';
  }

  // Height should be in inches
  // Raw height is in feets like 17.5
  const ft = parseInt(rawHeight, 10);
  const inch = Math.round((rawHeight - ft) * 12);
  const height = ft * 12 + inch;

  const BMI = (weight / (height * height)) * 703;
  let roundedBMI = Math.floor(BMI);
  let diff = Math.round((BMI - roundedBMI) * 10);

  if (diff === 10) {
    roundedBMI += 1;
    diff = 0;
  }

  return `${roundedBMI}.${diff}`;
}

function AvatarUploader({ patientId, avatarURL }) {
  // You could pass an initial avatar URL as a prop if it already exists
  const [avatarUrl, setAvatarUrl] = React.useState(avatarURL || null);
  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const fileInputRef = React.useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Optionally show a preview immediately (before uploading to the server)
    const previewUrl = URL.createObjectURL(file);
    setAvatarUrl(previewUrl);

    // Create form data
    const formData = new FormData();
    formData.append('avatar', file);

    setIsLoading(true);

    sendRequest(`connections/patients/${patientId}/avatar_change`, 'PUT', formData, true, true)
      .then((response) => {
        if (response.status === 'error') {
          setError(response.message);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error while uploading avatar:', error);
        setError('Something went wrong. Please try again later or contact support.');

        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

      {/* Hidden file input (triggered by clicking on image/placeholder) */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Clickable area: either show the avatar or a placeholder box */}
      {avatarUrl ? (
        <button onClick={handleClick} type="button" className="btn-link">
          <img
            src={avatarUrl}
            alt="User Avatar"
            className="avatar-image"
          />
        </button>
      ) : (
        <button type="button" onClick={handleClick} className="btn-link upload-avatar-placeholder">
          Upload Picture
        </button>
      )}
    </div>
  );
}

AvatarUploader.propTypes = {
  patientId: PropTypes.string.isRequired,
  avatarURL: PropTypes.string,
};

AvatarUploader.defaultProps = {
  avatarURL: null,
};

const CommonInfo = () => {
  const history = useHistory();
  const { patientId } = useParams();
  const modalRemoveConnectionConfirm = React.useRef(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: {}, message: '', error: '', isLoading: true, isError: false,
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          const patientInfo = response.data;

          // Create empty object if there is no patient.patient_profile attribute
          // There is a case when patient.patient_profile is null for some reason
          if (!patientInfo.patient.patient_profile) {
            patientInfo.patient.patient_profile = {};
          }

          dispatch({ type: 'FETCH_SUCCESS', payload: patientInfo });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [patientId]);

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }
  }, []);

  const handleStatusChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const { value } = e.target;

    sendRequest(`connections/patients/${patientId}/status_change`, 'PUT', { status: value })
      .then((response) => {
        if (response.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            message: response.message,
            payload: {
              ...state.data,
              status: value,
            },
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleHighRiskChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const value = e.target.checked;

    sendRequest(`connections/patients/${patientId}`, 'PUT', { needs_attention: value })
      .then((response) => {
        if (response.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            message: response.message,
            payload: {
              ...state.data,
              patient: {
                ...state.data.patient,
                patient_profile: {
                  ...state.data.patient.patient_profile,
                  needs_attention: value,
                },
              },
            },
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleRemoveConnection = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${state.data.patient.id}`, 'DELETE')
      .then((response) => {
        if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
          history.replace('/');
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  if (state.isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Common Info

        <a href={`/patients/${state.data.patient.id}/edit`} className="btn btn-small white black-text right hide-on-print">Edit</a>
        <button type="button" data-target="remove-modal" className="btn btn-small white red-text right hide-on-print mr2 modal-trigger">
          Remove
        </button>
      </h5>

      <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
      <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

      <div className="row">
        <AvatarUploader patientId={state.data.patient.id} avatarURL={state.data.patient.patient_profile.avatar} />
      </div>

      <div className="row">
        <div className="col s3">
          <h6>First Name</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.first_name}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Last Name</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.last_name || ''}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Status</h6>
        </div>
        <div className="col s9">
          <Select
            name="status"
            label="Status"
            values={PATIENT_STATUSES}
            defaultValue={state.data.status}
            onChange={handleStatusChange}
          />
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Phone Number</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.phone_number}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Email</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.email}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Gender</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.gender === 'F' ? 'Female' : 'Male'}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Date of Birth</h6>
        </div>
        <div className="col s9">
          <h6><strong>{state.data.patient.patient_profile.date_of_birth}</strong></h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Height</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {parseFloat(state.data.patient.patient_profile.height_in_ft || '0.0').toFixed(2)}
              {' '}
              ft
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Weight</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {parseFloat(state.data.patient.patient_profile.weight_in_lbs || '0.0').toFixed(2)}
              {' '}
              lbs
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>BMI</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {`${calculateImperialBMI(state.data)}`}
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Needs Attention</h6>
        </div>
        <div className="col s9">
          <div className="switch high-risk-switch">
            <label>
              No
              <input
                name="high_risk"
                type="checkbox"
                defaultChecked={state.data.patient.patient_profile.needs_attention}
                onChange={handleHighRiskChange}
              />
              <span className="lever" />
              Yes
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Chronic Conditions</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
                state.data.patient.patient_profile.chronic_conditions
                  ?.filter((cc) => cc !== '')
                  .map((cc) => (
                    <span key={cc} className="chip">
                      {cc}
                    </span>
                  ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Medication</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
              state.data.patient.patient_profile.medications
                ?.filter((m) => m !== '')
                .map((m) => (
                  <span key={m} className="chip">
                    {m}
                  </span>
                ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Conditions</h6>
        </div>
        <div className="col s9">
          <h6>
            <strong>
              {
                state.data.patient.patient_profile.conditions
                  ?.filter((c) => c !== '')
                  .map((c) => (
                    <span key={c} className="chip">
                      {c}
                    </span>
                  ))
              }
            </strong>
          </h6>
        </div>
      </div>

      <div className="row">
        <div className="col s3">
          <h6>Emergency Contact</h6>
        </div>
        <div className="col s9">
          <h6>
            <dl>
              <strong>Name: </strong>
              {state.data.patient.patient_profile.emergency_contact_name}
              <br />

              <strong>Phone: </strong>
              {state.data.patient.patient_profile.emergency_contact_phone}
              <br />

              <strong>Email: </strong>
              {state.data.patient.patient_profile.emergency_contact_email}
              <br />

              <strong>Relationship: </strong>
              {state.data.patient.patient_profile.emergency_contact_relationship}
            </dl>
          </h6>
        </div>
      </div>

      <ConnectedDevices patientId={state.data.patient.id} />

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id="remove-modal" className="modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</a>
          <a href="#!" className="modal-close waves-effect btn-flat">No</a>
        </div>
      </div>

    </div>
  );
};

export default CommonInfo;
