import React from 'react';
import moment from 'moment';
import { reducer, sendRequest } from '../utils';
import Loader from '../Components/Loader';
import RangeButtonsGroup from '../Patients/DetailsButtons';

const UsageStatisticsDashboard = () => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      from_date: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      to_date: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
    };

    sendRequest('admin/statistic', 'GET', opts)
      .then((response) => {
        if (response.status === 'success') {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: response.data,
          });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.error });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: error.message || 'Something went wrong' });
      });
  }, [startDate, endDate]);

  const handleIntervalChanges = ({ startDate, endDate }) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  return (
    <div className="container mt4 mb4">
      <a href="/admin/" className="btn btn-small white black-text">
        <i className="material-icons left">arrow_back</i>
        Go Back to Admin Dashboard
      </a>

      <h4>Usage Statistics</h4>

      {state.isError && <div className="red-text">{state.error}</div>}

      <RangeButtonsGroup
        defaultInterval="monthly"
        // defaultPeriod={0}
        initialStartDate={startDate}
        initialEndDate={endDate}
        callback={handleIntervalChanges}
      />

      {
      state.isLoading
        ? <Loader />
        : (
          <>
            <div className="row">
              <div className="col s12 m4">
                <div className="card">
                  <div className="card-content">
                    <h5 className="text-center mt0">Total Patients</h5>
                    <p className="h3 text-center font-weight-bold mt2 mb2">{state.data.total_patients}</p>
                  </div>
                </div>
              </div>

              <div className="col s12 m4">
                <div className="card">
                  <div className="card-content">
                    <h5 className="text-center mt0">Patients with Readings</h5>
                    <p className="h3 text-center font-weight-bold mt2 mb2">{state.data.count_patients_has_reading}</p>
                  </div>
                </div>
              </div>

              <div className="col s12 m4">
                <div className="card">
                  <div className="card-content">
                    <h5 className="text-center mt0">Average Minutes Spent</h5>
                    <p className="h3 text-center font-weight-bold mt2 mb2">{state.data.avg_minutes_spent}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

export default UsageStatisticsDashboard;
